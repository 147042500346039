import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
import useComponentSize from "@rehooks/component-size";
import type { HeadFC } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { DesktopId } from "../app/desktops";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ProgramWindow } from "../components/gui/organisms/program-window/program-window";
import { DesktopLayoutFloating } from "../components/layout/desktop-layouts/desktop-layout-floating";
import { ResumeAboutWindowContent } from "../components/resume/resume-content";
import ResumeDoc from "../components/resume/resume-doc";
import { ResumeToolbar } from "../components/resume/resume-toolbar";
import { resetDesktopWindowUserTranslate } from "../store/desktops/desktops-slice";
import {
  ResumeColorMode,
  ResumeLayoutMode,
  resumeConfigSelector,
  setShowModalAboutMe,
} from "../store/resume/resume-slice";

const StyledResumeWindowContentContainer = styled.div`
  width: calc(100vw - 50px);
  max-width: 1000px;
  max-height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
`;

const StyledResumeDocContainer = styled.div<{
  $usePrintLayout: boolean;
}>`
  ${(props) => props.$usePrintLayout && `padding: 0 40px`};
  margin: 0 auto;
  overflow: auto;
  width: 100%;
  max-width: 100%;
`;

const ResumePage = () => {
  const resumeContainerRef = React.useRef<HTMLDivElement>(null);
  const { width: resumeContainerWidth } = useComponentSize(resumeContainerRef);

  const { resumeLayoutMode, resumeColorMode, showModalAboutMe } =
    useAppSelector(resumeConfigSelector);

  const usePrintLayout =
    resumeLayoutMode === ResumeLayoutMode.PRINT ||
    (resumeLayoutMode === ResumeLayoutMode.AUTO && resumeContainerWidth >= 800);
  const useGrayscaleMode = resumeColorMode === ResumeColorMode.GRAYSCALE;

  const [windowWidth, setWindowWidth] = React.useState(
    document.body.getBoundingClientRect().width
  );
  useResizeObserver(document.body, ({ contentRect }) =>
    setWindowWidth(contentRect.width)
  );

  const dispatch = useAppDispatch();

  // TODO: store the user's custom positioning of the windows with/without About Me open, and restore it when changing?
  React.useEffect(() => {
    dispatch(resetDesktopWindowUserTranslate({ windowId: "resume-viewer" }));
    dispatch(resetDesktopWindowUserTranslate({ windowId: "resume-contact" }));
  }, [dispatch, resetDesktopWindowUserTranslate, showModalAboutMe]);

  // TODO: figure out if I have an easy way to preserve the old DesktopLayoutBasicColumn behaviour only on mobile, while having a floating modal About Me window
  return (
    <DesktopLayoutFloating desktopId={DesktopId.RESUME}>
      <ProgramWindow
        windowId="resume-viewer"
        title="Resume"
        applyContentPadding={false}
        defaultGeometry={{
          defaultTranslateX:
            windowWidth < 1200 ? 0 : showModalAboutMe ? "min(100px, 30vw)" : 0,
        }}
      >
        <StyledResumeWindowContentContainer>
          <ResumeToolbar enableShowModalAboutMe={true} printInPopup={true} />
          <StyledResumeDocContainer
            ref={resumeContainerRef}
            $usePrintLayout={usePrintLayout}
          >
            <ResumeDoc
              usePrintLayout={usePrintLayout}
              useGrayscaleMode={useGrayscaleMode}
            />
          </StyledResumeDocContainer>
        </StyledResumeWindowContentContainer>
      </ProgramWindow>
      <ProgramWindow
        windowId="resume-contact"
        title="Contact"
        defaultGeometry={{
          defaultTranslateX: windowWidth < 1200 ? 0 : "max(-30vw, -600px)",
          zIndexPriority: 1,
        }}
        buttons={[
          {
            id: "resume-contact-close",
            label: <FontAwesomeIcon icon={faClose} />,
            onClick: () => dispatch(setShowModalAboutMe(false)),
            alt: "Close the Contact Me content",
          },
        ]}
        isMinimized={!showModalAboutMe}
      >
        <ResumeAboutWindowContent />
      </ProgramWindow>
    </DesktopLayoutFloating>
  );
};

export default ResumePage;

export const Head: HeadFC = () => <title>Resume | simontang.dev</title>;
